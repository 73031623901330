import React from 'react'

// import layout
import WorkLayout from '../../layout/work-layout'

// components
import { StaticImage } from 'gatsby-plugin-image'
import ContactLink from '../../micro-components/contact-link'
import Seo from '../../utils/seo'

const Work = () => {
  // DEFINE THERE ALL THE WORK CONSTs
  const title = 'Marco Angioloni',
    desc =
      `Realizzazione di 2 video musicali ed un teaser trailer per l'uscita dell'album di debutto di Marco Angioloni.`,
    subtitle = '',
    roles = ['Assistente della fotografia'],
    videoSrc = [
      'https://www.youtube.com/watch?v=1kQcf2RodKc',
      'https://www.youtube.com/watch?v=6bxXkVu7Scs',
      'https://www.youtube.com/watch?v=NkFBs7R6TxI',
    ],
    // put this TRUE only if there are logos to show
    logo = false

  return (
    <WorkLayout
      title={title}
      subtitle={subtitle}
      roles={roles}
      videoSrc={videoSrc}
      logo={logo}
    >
      <Seo title={title} description={desc} />
      {/* COVER IMG */}
      <StaticImage
        src='../../images/cover-marco-angioloni.jpg'
        alt='Cover per il lavoro Marco Angioloni'
        className='work-cover shadow-black'
      />
      {/* DESC */}
      <p className='work-description desc-1 margin-bottom'>
        <span>Album uscito il 4 febbraio 2022.</span>
        <span>
          Progetto in collaborazione con{' '}
          <ContactLink
            to='https://www.leonardocasalini.com/'
            external={true}
            title='Per il sito di Leonardo Casalini'
          >
            {' '}
            Leonardo Casalini
          </ContactLink>
          .
        </span>
      </p>
      {/* DESC n.2 */}
      <p className='work-description desc-2 margin-bottom'>
        <span>
          Un Tenore Barocco - Arias per Annibale Fabbri di{' '}
          <em>Marco Angioloni</em> con Il Groviglio e Stéphane Fuget, esplora la
          lunga carriera di uno dei più leggendari cantanti lirici dell'età
          barocca, il tenore Annibale Fabbri.
        </span>
        <span>
          <ContactLink
            to='https://open.spotify.com/album/6t4qXcURhxN6qS3RSAX6jx?si=c7WACOIkTh-wodfjInN0pQ&nd=1'
            external={true}
            type='none'
          >
            Scopri l'album
          </ContactLink>
        </span>
      </p>
      {/* DESC n.3 */}
      <p className='work-description desc-3'>
        <span>
          L'album esplora circa 20 anni del periodo barocco, include arie di
          grandi personaggi come Handel, Vivaldi, Alessandro Scarlatti e di
          compositori i cui nomi hanno riguadagnato moneta negli ultimi decenni,
          come Domenico Sarro o Antonio Caldara (che scrisse il virtuosistico
          'Leon Piagato a morte' - una delle anteprime mondiali dell'album).
        </span>
      </p>
      {/* ADD HERE ALL THE LOGOS */}
    </WorkLayout>
  )
}

export default Work
